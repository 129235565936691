/* ==========================================================================
  Variables
========================================================================== */

@font-face {
    font-family: 'optician_sansregular';
    src: url('../fonts/optician-sans-webfont.woff2') format('woff2'),
         url('../fonts/optician-sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$body_font: "linotype-sabon",sans-serif;
$title_font: "optician_sansregular",sans-serif;


/* ==========================================================================
  Colours
========================================================================== */

$color1: #476E7D;
$color2: #0A0F11;
$color3: #9DC8DA;
$color4: #D6DDE1;
$color5: #7A1316;
$color6: #2A414B;
$color7: #142329;
$color8: #344A54;
$color9: #1d1d1b;
$footerColour: #4e6d7c;
