/* ==========================================================================
  Imports
========================================================================== */

@import "variables";
@import "mixins";
@import "reset";
@import "animate";
@import "provenance";
@import "instantWin";
@import "customerLoyalty";

/* ==========================================================================
  Global Styles
========================================================================== */

body {
  @include body_font_regular;
  padding: 0;
  margin: 0;
  letter-spacing: normal;
  line-height: 1.3;
  font-size: 100%;
  background-color: $color2;
  color: $color3;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
  font-variant-numeric: lining-nums;
}

::selection {
  background-color: $color3;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  overflow: hidden !important;
}

.fullscreen {
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.fillscreen {
  min-height: 70vh;
  width: 100vw;
  overflow: hidden;
}

.gradient-bg {
  &:before {
    @include gradient-bg;
    content: "";
    position: absolute;
    top: -50vh;
    left: -50vw;
    height: 200vw;
    width: 200vw;
  }
}

.center-text {
  position: relative;
  text-align: center;
}

.footer {
  padding: 35px 20% 45px 20%;
  background: $color9;
  color: $footerColour;
  line-height: 1.5;
  .GCLLogo, .NestgateLogo{
    max-height: 40px;
    display:block;
    margin:5px auto 30px auto;
  }
}

.container {
  position: relative;
  padding: 0 25px;
}

.container-sm {
  width: 60%;
  margin: 0 auto;
}

form {
  position: relative;
}

.light-bg {
  background-color: $color7;
}

.vertical-align {
  @include vert-align;
}

/* ==========================================================================
  Images
========================================================================== */

.icon {
  display: block;
  position: relative;
  @include icon;
}

.image {
  display: block;
  @include background-image;
}

.logo {
  width: 100vw;
  z-index: 10;
  background-image: url("../images/digitus_logo.svg");
}

.logo-sm {
  position: absolute;
  top: 40px;
  height: 35px;
}

.logo-md {
  height: 35px;
}

.logo-lg {
  position: absolute;
  top: 40px;
  height: 50px;
}

.bottle-img {
  background: url("../images/digitus_bottle.png") top center no-repeat;
}

.bottle-img-overlay {
  position: relative;
  top: 15vh;
  margin: 0 auto;
}

.intro-text-wrapper {
  @include gradient-overlay;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 60px 0 20px;
}

.intro-text {
  position: relative;
  z-index: 10;
}

.path {
  stroke-dasharray: 100;
  animation: dash 10s linear infinite;
}

.path-long {
  stroke-dasharray: 200;
  animation: dash 5s linear infinite;
}


/* ==========================================================================
  Text
========================================================================== */

h1, h2, h4, h5 {
  @include title_font_regular;
}

h1 {
  font-size: 33px;
  line-height: .9;
  margin: 6px auto;
}

h2 {
  font-size: 24px;
  line-height: 1;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 22px;
  line-height: 1;
}

h5 {
  font-size: 18px;
}

p, p a {
  font-size: 18px;
}

a {
  color: $color3;
  text-decoration: underline;
}

.small-text {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.3;
}

.white-text {
  color: #FFF;
}

.color1-text {
  color: $color1;
}

.color6-text {
  color: $color6;
}

.grey-text {
  color: $color4;
}

.text-box {
  position: relative;
  z-index: 10;
}


/* ==========================================================================
  Buttons
========================================================================== */

.btn {
  @include title_font_regular;
  @include round-corners;
  display: block;
  padding: 12px;
  width: 100%;
  border: 0;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.btn-primary {
  background-color: $color5;
  color: #FFF;
}

button[disabled] {
  opacity: .5;
}

/* ==========================================================================
  Forms
========================================================================== */

.react-code-input {
  margin: 20px auto;
  input {
    @include round-corners;
    font-family: $body_font !important;
    width: 21% !important;
    height: 90px !important;
    font-size: 60px !important;
    text-align: center;
    padding: 0 !important;
    margin: 0 5% 0 0 !important;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0 !important
    }
  }
}

.error {
  margin: 0 0  20px 0;
  color: $color5;
}

.field {
  @include body_font_regular;
  @include round-corners;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 20px 0;
  border: none;
}

/* ==========================================================================
  Homepage
========================================================================== */

.nav-link {
  @include title_font_regular;
  display: block;
  margin: 60px 0;
  font-size: 30px;
  text-decoration: none;
  text-align: center;
}


/* ==========================================================================
  Loading
========================================================================== */

.progress {
  height: 24px;
  width: 24px;
  svg {
    width: 26px;
    height: 26px;
  }
}

.progress-sm {
  margin: 8% auto 0;
}

.progress-md {
  margin: 15% auto 0;
  svg {
    transform: scale(1.2);
  }
}

.progress-lg {
  margin: 15% auto 0;
  svg {
    transform: scale(2);
  }
}
