/* ==========================================================================
  Provenance
========================================================================== */


.provenance-loader {
  .text-box {
    margin-top: -80px;
  }
}

.provenance-bottle-img {
  height: 65vh;
  width: 50vw;
  background-size: 90% !important;
}

.timeline-item-wrapper {
  position: relative;
  display: block;
  padding-top: 120px;
}

.inview {
  .timeline-line {
      display: block;
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      border-left: 1px solid rgba($color1,.5);
      height: 0;
      width: 1px;
      animation: .6s linear 0s grow-line forwards;
  }
}


.timeline-item-block {
  @include gradient-bg;
}

.timeline-image {
  width: 100vw;
  margin: 10px 0;
}

.timeline-details {
  margin: 20px 30px;
  .large-icon:first-of-type {
    margin-top: 30px;
  }
}

.timeline-detail {
  margin: 10px 0;
  display: inline-flex;
}

.timeline-icon {
  display: inline-block !important;
  height: 24px;
  width: 24px;
  margin-right: 30px;
}

.timeline-label {
  width: 70%;
}

.timeline-date {
  position: absolute;
  right: 30px;
}

.timeline-map {
  position: relative;
  width: calc(100vw - 60px);
  height: 200px;
  margin: 20px 0;
}

.large-icon {
  margin: 10px 0 10px 25%;
  width: 70%;
  .timeline-label {
    color: #FFF;
  }
}

.winery {
  display: block;
  width: 60%;
  margin: 0 auto;
  .timeline-date {
    position: relative;
    right: 0;
    left: 56px;
  }
  .timeline-icon {
    top: 12px;
  }
  .timeline-label {
    display: inline-block;
  }
}

.calendar-icon {
  background-image: url("../images/calendar.svg");
}

.pin-icon {
  background-image: url("../images/pin.svg");
}

.cherry-icon {
  background-image: url("../images/cherry.svg");
}

.cedar-icon {
  background-image: url("../images/cedar.svg");
}

.cinammon-icon {
  background-image: url("../images/cinnamon.svg");
}
